import { navigate } from "gatsby";
import { useEffect } from 'react';
import { useLocation } from "@reach/router";

const IndexPage = () => {
  const location = useLocation();

  useEffect(() => {
        const token = location.search.substring(1);

        window.location.href =  `/apiweb/customer/veridas?token=${token}`;
  }, [location]);

  return null;
};

export default IndexPage;